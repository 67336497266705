
body {
  font-family: 'Poppins', sans-serif;
}

.App {  
  justify-content: center;
}


.nav-link {
  color:#75b6e0 !important;
}


.text-link {
  color: inherit;
  text-decoration: inherit;
  color:#75b6e0 !important;
}

.navbar-brand {
  color:#75b6e0 !important;
  font-weight: bold !important;
}





